import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  Dispatch,
  JSX,
  SetStateAction,
  useCallback,
} from 'react';
import styled from 'styled-components';

import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {useTheme} from '@shared/frontends/theme_context';
import {randomStringUnsafe} from '@shared/lib/rand';
import {AddPrefix, addPrefix} from '@shared/lib/type_utils';

import {LabelPosition} from '@shared-frontend/components/core/input_v2';
import {Label} from '@shared-frontend/components/core/label';
import {cssPx, optional, optionalPx, optionalRaw} from '@shared-frontend/lib/styled_utils';

interface SelectProps<T> {
  placeholder?: string;
  value: T;
  values: {value: T; label: string}[];
  syncState: Dispatch<SetStateAction<T>> | ((val: T, el: HTMLSelectElement) => void);
  asString?: (value: T) => string;
  fromString?: (value: string) => T;
  width?: string | number;
  overrides?: Partial<FrontendTheme['input']>;
  label?: string | JSX.Element;
  labelPosition?: LabelPosition;
  noLabelOffset?: boolean;
}
export function Select<T>(
  props: SelectProps<T> & Omit<ComponentPropsWithoutRef<'select'>, 'style' | 'children'>
): JSX.Element {
  const {
    placeholder,
    value,
    values,
    syncState,
    asString,
    fromString,
    width,
    overrides,
    label,
    labelPosition,
    noLabelOffset,
    ...rest
  } = props;
  const unselectedValue = randomStringUnsafe(10);

  const {input: themeDefault} = useTheme();
  const inputTheme = addPrefix({...themeDefault, ...overrides}, '$');

  const handleChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    evt => {
      const val =
        fromString === undefined
          ? (evt.currentTarget.value as unknown as T)
          : fromString(evt.currentTarget.value);
      syncState(val, evt.currentTarget);
    },
    [fromString, syncState]
  );

  const select = (
    <StyledSelect
      onChange={handleChange}
      value={value ?? unselectedValue}
      $width={width}
      {...inputTheme}
      {...rest}
    >
      {placeholder !== undefined ? (
        <Option key="placeholder" value={unselectedValue} disabled>
          {placeholder}
        </Option>
      ) : (
        ''
      )}
      {values.map(v => {
        const str = asString === undefined ? (v.value as unknown as string) : asString(v.value);
        return (
          <Option key={str} value={str}>
            {v.label}
          </Option>
        );
      })}
    </StyledSelect>
  );

  if (label !== undefined) {
    const labelBaseColor = inputTheme.$textColor;
    const labelColor = /#[\dA-Fa-f]{6}/u.test(labelBaseColor)
      ? `${labelBaseColor}99`
      : /#[\dA-Fa-f]{3}/u.test(labelBaseColor)
        ? `${labelBaseColor}${labelBaseColor.slice(1, 4)}99` // eslint-disable-line @typescript-eslint/no-magic-numbers
        : labelBaseColor;
    return (
      <StyledLabel
        value={label}
        $paddingLeft={noLabelOffset ? 0 : inputTheme.$paddingLeft}
        $labelPosition={labelPosition ?? 'left'}
        $fontSize={inputTheme.$fontSize}
        $marginBottom={inputTheme.$titleMarginBottom}
        $textColor={labelColor}
      >
        {select}
      </StyledLabel>
    );
  }

  return select;
}
Select.displayName = 'Select';

const StyledSelect = styled.select<
  AddPrefix<FrontendTheme['input'], '$'> & {$width: SelectProps<unknown>['width']}
>`
  display: block;
  ${p => optionalPx('width', p.$width)}
  ${p => optionalPx('height', p.$height)}
  box-sizing: border-box;

  outline: none;
  ${p => optionalPx('padding-right', p.$paddingRight)}
  ${p => optionalPx('padding-left', p.$paddingLeft)}
  ${p => optionalPx('border-radius', p.$borderRadius)}

  ${p => optional('font-family', p.$fontFamily)}
  ${p => optional('font-family', p.$fontWeight)}
  ${p => optionalPx('font-size', p.$fontSize)}

  ${p => optional('color', p.$textColor)}
  ${p => optionalRaw(p.$borderWidth, v => `border: solid ${cssPx(v)} ${p.$borderColor};`)}
  ${p => optional('background-color', p.$backgroundColor)}

  &:hover {
    ${p => optional('background-color', p.$backgroundColorHover)}
    ${p => optional('border-color', p.$hoverBorderColor)}
  }

  &:active:not([disabled]),
  &:focus:not([disabled]) {
    ${p =>
      optionalRaw(p.$focusBorderWidth, v => `border: solid ${cssPx(v)} ${p.$focusBorderColor};`)}
    ${p =>
      optionalRaw(
        p.$focusOutlineWidth,
        v => `box-shadow: 0 0 0 ${cssPx(v)} ${p.$focusOutlineColor};`
      )}
    ${p => optional('color', p.$focusTextColor)}
    ${p => optional('background-color', p.$backgroundColorFocus)}
  }

  &:disabled {
    ${p => optional('color', p.$textColorDisabled)}
    ${p => optional('background-color', p.$backgroundColorDisabled)}
    box-shadow: none;
    ${p => optionalRaw(p.$borderWidth, v => `border: solid ${cssPx(v)} ${p.$borderColor};`)}
  }
`;

const Option = styled.option``;

const StyledLabel = styled(Label)<{
  $paddingLeft: number | string | undefined;
  $labelPosition: LabelPosition;
  $fontSize: number | string | undefined;
  $marginBottom: number | string;
  $textColor: string;
}>`
  display: inline-block;
  line-height: 120%;
  font-weight: 700;
  color: ${p => p.$textColor};
  ${p => optionalPx('padding-left', p.$labelPosition === 'left' ? p.$paddingLeft : undefined)}
  ${p => optionalRaw(p.$fontSize, v => `font-size: calc(${cssPx(v)} * 0.8);`)}
  ${p => (p.$labelPosition === 'center' ? 'text-align: center;' : false)}
  margin-bottom: ${p => cssPx(p.$marginBottom)};
`;
