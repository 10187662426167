import {CommitHash, ParameterKey} from '@shared/dynamo_model';

export const LOCAL_COMMIT_HASH = 'local' as CommitHash;

export const MONITORING_SUCCESSIVE_SECONDS = 5;

export const LB_REQUEST_TIMEOUT = 60;
export const KEEPALIVE_TIMEOUT = 30;
export const MAX_DRAINING_TIME = 60;
export const API_REQUEST_TIMEOUT = 5;
export const AWS_REQUEST_TIMEOUT = 15;
export const SSE_TIMEOUT = 5;
export const NO_TIMEOUT = 5 * 60 * 1000; // 5 minutes

export const ALERTS_PARAMETER_KEY = 'ALERTS' as ParameterKey;

export const MONITORING_GRAPH_NUMBER_OF_POINTS = 300;

export const CAPTCHA_HEADER_NAME = 'x-captcha';
export const NEXT_CAPTCHA_HEADER_NAME = 'x-next-captcha';
